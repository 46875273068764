import axios from "axios";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Icon from "@mui/material/Icon";
import { Typography } from "@mui/material";

import Layout from "../../components/Layout/Layout";
import { getKBbySize, isMobileDevice } from "../../utils/utils";

import loading from "../../assets/loading.svg";

// const ejemplo = B1E31129-6796-4B6B-BC49-00B8F4926BDB
function Home() {
  const [documents, setDocuments] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const uidSet = location.state ? location.state.uidSet : "";

  // Call API and GET TOKEN AUTH & GET DOCUMENTS
  useEffect(() => {
    const data = {
      userName: process.env.REACT_APP_MASTER_USER,
      password: process.env.REACT_APP_PASSWORD_MASTER_USER,
      grant_type: "password",
    };

    const headers = {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    };

    axios
      .post(`${process.env.REACT_APP_API_AGP}\\auth\\token`, data, headers)
      .then((res) => {
        if (uidSet !== undefined && uidSet !== null) {
          let config = {
            headers: {
              Authorization: "Bearer " + res.data.access_token,
            },
          };

          setIsLoading(true);

          axios
            .get(
              `${process.env.REACT_APP_API_AGP}\\api\\conjunto-operacion\\${uidSet}`,
              config
            )
            .then((res) => {
              setDocuments(res.data.documentos);
            })
            .catch((err) => {
              console.error(err.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [uidSet]);

  const navigate = useNavigate();

  return (
    <>
      <Layout title="Visualizador de Documentos"/>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <Icon
          sx={{
            width: "50px",
            height: "50px",
          }}
        >
          <img alt="Loading..." src={loading} />
        </Icon>
      </Backdrop>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {documents &&
          documents.map((doc, index) => {
            return (
              <Card
                onClick={() =>
                  navigate(`${index}`, {
                    replace: false,
                    state: { doc },
                  })
                }
                style={{
                  cursor: "pointer",
                  width: "18rem",
                  margin: "1rem",
                }}
                key={index}
              >
                <CardContent>
                  {!isMobileDevice(navigator) && (
                    <embed
                      src={`data:application/pdf;base64,${doc.archivo}`} //data:image/png;base64,
                      style={{
                        overflow: "hidden",
                      }}
                    />
                  )}

                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {doc.tipoDocumento && doc.tipoDocumento.nombre}
                  </Typography>
                  <List>
                    <ListItem>{doc.observacion?.toUpperCase()}</ListItem>
                    <Divider></Divider>
                    <ListItem>{doc.extension}</ListItem>
                    <Divider></Divider>
                    <ListItem>
                      {doc.peso ? `${getKBbySize(doc.peso)} KB` : "0 KB"}
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            );
          })}
        {documents && Object.keys(documents).length < 1 ? (
          <Container sx={{ marginTop: "3rem" }}>
            <Alert severity="error">
              No hay documentos asociados a esta operación.
            </Alert>
          </Container>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

export default Home;
