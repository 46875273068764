import { createTheme } from "@mui/material/styles";
import "@fontsource/open-sans"; // Defaults to weight 400
import "@fontsource/open-sans/400.css"; // Specify weight
import "@fontsource/open-sans/400-italic.css"; // Specify weight and style

const theme = createTheme({
  palette: {
    primary: {
      light: '#4ba3cb',
      main: '#1C4375',
      dark: '#4b4344',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: "#F6F7F9",
      dark: '#1C4375',
      contrastText: "#4b4344",
    },
    success: {
      main: "#06c270",
    },
    warning: {
      main: "#ffcc00"
    },
    error: {
      main: "#ff3b3b"
    },
    info: {
      main:"#006cf8"
    }

  },
  typography: {
    fontFamily: "Open Sans",
    fontSize: 16,
    h1: {
      fontWeight: 700,
      fontSize: "48px",
    },
    h2: {
      fontWeight: 700,
      fontSize: "40px",
    },
    h3: {
      fontWeight: 700,
      fontSize: "32px",//"2.2rem",
    },
    h4: {
      fontWeight: 700,
      fontSize: "28px",
    },
    h5: {
      fontWeight: 500,
      fontSize: "24px",
    },
    h6: {
      fontWeight: 500,
      fontSize: "20px",
    },
    button: {
      textTransform: 'none',
    },
  },
});

export default theme;
