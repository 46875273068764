import Typography from "@mui/material/Typography/Typography";
import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import logo from "../../assets/AGPalternativeLogo.svg";

function Footer() {
  return (
    <AppBar position="static" color="primary"
    sx={{ marginTop:"auto" }}
    >
      <Toolbar>
        <img
          src={logo}
          width="100"
          // height="30"
          alt="AGP logo"
          className="m-icon"
        ></img>
        <Typography variant="p" component="div" sx={{ flexGrow: 1 }}>
        Copyright © 2023 AGP S.A. All Rights Reserved.
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
