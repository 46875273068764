import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography/Typography";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../components/Layout/Layout";
function Error() {
  return (
    <>
    <Layout title="Visualizador de Documentos"/>
    <Box>
      <Container
        sx={{
          padding: "5rem",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center"
        }}
      >
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          size="6x"
          style={{
            color: "#1C4375",
            padding: "1rem",
          }} />
        <Box
          sx={{
            padding: "1rem",
          }}
        >
          <Typography variant="h1" color="secondary.dark" component="div">
            404
          </Typography>

          <Typography variant="p" color="secondary.dark" component="div">
            Página no encontrada.
          </Typography>
        </Box>
      </Container>
    </Box></>
  );
}

export default Error;
