import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Layout from "../../components/Layout/Layout";

import { faCircleCheck, faFolder, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function SuccessSignature(props) {

  const navigate = useNavigate();

  const { uidSet } = useParams();
  const redirectButton = () => {
    window.open('https://portal-automotriz-prod.azurewebsites.net/');
  }

  return (
    <Box>
      <Layout title="Validación de Firma Electrónica"/>
      <Container
      sx={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
      }}
      >
      <Container
        sx={{
          paddingTop: "5rem",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center"
        }}
      >
        <FontAwesomeIcon
          icon={faCircleCheck}
          size="6x"
          style={{
            color: "#1C4375",
            padding: "1rem",
          }}
          light
          regular
        />
        <Box
          sx={{
            padding: "1rem",
          }}
        >
          <Typography variant="h1" color="secondary.dark" component="div">
            ¡Firma Realizada Exitosamente!
          </Typography>
        </Box>
      </Container>
      { props.gotoView &&    
        <Button onClick={() => navigate("/g/"+uidSet, { replace: true })}>
          <FontAwesomeIcon
            icon={faFolder}
            style={{
              paddingRight: "0.5rem",
            }} />
            Ver Documentos
        </Button>}
        <Button onClick={redirectButton}>
            Ir al Portal
            <FontAwesomeIcon
            icon={faArrowRight}
            style={{
              paddingLeft: "0.5rem",
            }} />
        </Button>
      </Container>
    </Box>
  );
}

export default SuccessSignature;
