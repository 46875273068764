import React from "react";
import { useRoutes } from "react-router-dom";

import PDFViewer from "./views/PDFViewer/PDFViewer";
import Home from "./views/Home/Home";
import Error from "./views/Error/Error";
import Validator from "./views/Validator/Validator";
import SuccessSignature from "./views/SuccessSignature/SuccessSignature";

export function Routes() {
  const element = useRoutes([
    { path: "g/:uidSet", element: <Validator /> },
    { path: "g/docs", element: <Home /> },
    { path: "g/docs/:idDoc", element: <PDFViewer /> },
    { path: "successful-signature", element: <SuccessSignature gotoView={ false }/> },
    { path: "successful-signature/:uidSet", element: <SuccessSignature gotoView={ false } /> },
    { path: "*", element: <Error /> },
  ]);
  return element;
}
