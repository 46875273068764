import logo from "../../assets/AGPlogo.svg";
import "./Layout.css";
import AppBar from "@mui/material/AppBar";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

function Layout(props) {
  return (
    <>
      <AppBar position="static" color="secondary">
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <img
            src={logo}
            width="100"
            // height="30"
            alt="AGP logo"
            className="m-icon"
          ></img>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Layout;
