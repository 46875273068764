export function convertDataURIToBinary(dataURI) {
  if (dataURI !== null && dataURI !== undefined)
    return Uint8Array.from(
      window.atob(dataURI.replace(/^data[^,]+,/, "")),
      (v) => v.charCodeAt(0)
    );
  else return null;
}

export function base64toBlob(data) {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr(
    "data:application/pdf;base64,".length
  );

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
}

export function getKBbySize(size) {
  return (size / 1024).toFixed(2);
}

export function isMobileDevice(navigator) {
  return navigator.userAgentData.mobile;
}
