import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography/Typography";

import { useParams, useNavigate } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";
import { Box, Button } from "@mui/material";

import { useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleRight } from "@fortawesome/free-solid-svg-icons";
import Card from "@mui/material/Card";

function Validator() {
  const navigate = useNavigate();
  const { uidSet } = useParams();
  const captcha = useRef(null);
  // usf
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const onChange = () => {
    if (captcha.current.getValue()) {
      setButtonDisabled(false);
    }
  };

  return (
    <Container
      fixed
      sx={{
        display: "flex",
        height: "80vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          maxWidth: "500px",
          maxHeight: "200px",
          justifyContent: "space-evenly",
        }}
      >
        <Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Validación ReCaptcha
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "1rem",
          }}
        >
          <ReCAPTCHA
            ref={captcha}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={() => onChange()}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Button
            onClick={() =>
              navigate("../g/docs", {
                replace: true,
                state: { uidSet },
              })
            }
            variant="outlined"
            size="small"
            disabled={buttonDisabled}
          >
            <FontAwesomeIcon
              icon={faCircleRight}
              style={{
                paddingRight: "0.5rem",
              }}
            />
            Continuar
          </Button>
        </Box>
      </Card>
    </Container>
  );
}

export default Validator;
