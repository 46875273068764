import { useLocation } from "react-router-dom";
import { Worker, Viewer } from "@react-pdf-viewer/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom/dist";
import {
  convertDataURIToBinary,
  getKBbySize,
  isMobileDevice,
} from "../../utils/utils";

import Container from "@mui/material/Container/Container";
import Button from "@mui/material/Button/Button";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "./PDFViewer.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

import Layout from "../../components/Layout/Layout";

function PDFViewer() {
  const location = useLocation();
  const doc = location.state ? location.state.doc : "";

  let pdfFile;
  const navigate = useNavigate();

  pdfFile = convertDataURIToBinary(doc.archivo);

  return (
    <>
    <Layout title="Visualizador de Documentos"/>
    <Box
      sx={{
        paddingTop: "0.5rem",
      }}
    >
      <div
        style={{
          display: "flex",
          paddingBottom: "1rem",
          paddingTop: "1rem",
          alignItems: "center",
        }}
      >
        <Button onClick={() => navigate(-1)}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            style={{
              paddingRight: "0.5rem",
            }} />
        </Button>

        <Container>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {`${doc.tipoDocumento.nombre}`}
          </Typography>

          <Typography
            variant="subtitle"
            component="div"
            sx={{ flexGrow: 1, color: "#999999" }}
          >
            {`${getKBbySize(doc.peso)} KB (${doc.extension})`}
          </Typography>

          <Typography
            variant="subtitle"
            component="div"
            sx={{ flexGrow: 1, color: "#999999" }}
          >
            {doc.observacion?.toUpperCase()}
          </Typography>
        </Container>
        <div>
          <Button
            sx={{
              marginRight: "2rem",
            }}
            variant="outlined"
            size="small"
          >
            <a
              download={doc.tipoDocumento.nombre}
              href={`data:application/pdf;base64,${doc.archivo}`}
              style={{
                outline: "none",
                textDecoration: "none",
                color: "#1C4375",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faDownload}
                style={{
                  paddingRight: "0.5rem",
                }} />
              Descargar
            </a>
          </Button>
        </div>
      </div>

      <Container>
        {pdfFile && !isMobileDevice(navigator) && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl={pdfFile} />;
          </Worker>
        )}
      </Container>
    </Box></>
  );
}

export default PDFViewer;
