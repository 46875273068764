import "./App.css";
import { Routes } from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Footer from "./components/Footer/Footer";
import { ThemeProvider } from "@mui/system";
import theme from "./style/theme";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes />
        </Router>
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
